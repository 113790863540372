<template>
  <div id="home-view">
    <section class="bg-light ftco-section mt-lg-5">
      <div class="container">
        <div class="row flex-column-reverse flex-lg-row flex-md-row">
          <div class="col-md-5">
           
          
            <p class="text-black">
             Govava always understood that gift-giving is a thoughtful and significant act.  It bonds friends, family, and relationships. It deepens that sense of connection between two people. It is a connection between the "Gifter" and "Giftee".  Gift giving is more than the "Gift"..it is the thought.  When you need help "Finding The Perfect Gift" during holidays, birthdays, anniversaries, and memorable moments, Govava will help you to be better at gift-giving.
            </p>
            <img src="@/assets/site/images/arrow-down.svg" class="my-3" alt />
          </div>
          <div class="col-md-7">
            <img src="@/assets/site/images/group.png" alt class="img-fluid" />
          </div>
        </div>
      </div>
    </section>
    <section class="ftco-section1 bg-light mb-110">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h4 class="mb-5 text-black">About Govava</h4>
            <img src="@/assets/site/images/kids.png" alt class="img-fluid" />
          </div>
          <div class="col-md-6 mt-5">
           
            <p>
            Govava always understood that gift-giving is a thoughtful and significant act.  It bonds friends, family, and relationships. It deepens that sense of connection between two people. It is a connection between the "Gifter" and "Giftee".  Gift giving is more than the "Gift"..it is the thought.  When you need help "Finding The Perfect Gift" during holidays, birthdays, anniversaries, and memorable moments, Govava will help you to be better at gift-giving.
            </p>
          </div>
          <div class="col-md-1"></div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <img src="@/assets/site/images/loader.gif" v-if="loader===true" class="img-fluid  loader-width" alt />
      </div>
    </section>
    <!-- <section class="bg-grey ftco-section1" v-if="loader===false">
      <div class="container pos-100">
        <div class="row mb-3">
          <div class="col-md-6 d-flex justify-content-start">
            <h3 class="text-black">Collection</h3>
          </div>
          <div class="col-md-6 d-flex justify-content-end" @click="hideTopbar()">
            <!-- <a href="#">more</a> 
            <router-link to='/breast_pumps'>
              <p class="text-pri">More</p>
            </router-link>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-md-6" v-for="collection in collections">
            <product :product="collection" @apply-clicked="showOrderModal"></product>
          </div>
        </div>
      </div>
    </section>
    <section class="ftco-section bg-light mb-50">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <img class="img-fluid" src="@/assets/site/images/spc.png" alt />
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-5 text-paragraph">
            <h4>Breast Feeding Classes</h4>
            <div class="row mb-4">
              <div class="col">
                <span class="text3">
                  <img src="@/assets/site/images/zoom.svg" alt /> Zoom Supported
                </span>
              </div>
              <div class="col">
                <span class="text3">
                  <img src="@/assets/site/images/person.svg" alt /> In Person Limited
                </span>
              </div>
            </div>

            <p>
              This class is offered by Jodie our International Board Certified Lactation Consultant and Registered
              Nurse. This is a great class for expectant parents to take to prepare for the most important part of
              caring for the newborn, Breastfeeding. Jodie provides evidence based information and has had years of
              experience working with birth and breastfeeding... <router-link to='/lactation_res/classes' @click="hideTopbar">
                More
              </router-link>
            </p>
            <router-link to='/lactation_res/classes' @click="hideTopbar" class="btn btn-primary">Check it out</router-link>
          </div>
        </div>
        <p class="my-2">
          See more:
          <router-link to="/lactation_res/classes">Breastfeeding Support Group</router-link> |
          <router-link to="/lactation_res/classes">Prenatal Lactation Consults</router-link> 
        </p>
        <div class="d-flex justify-content-center">
          <img src="@/assets/site/images/loader.gif" v-if="loader===true" class="img-fluid  loader-width" alt />
        </div>
      </div>
    </section>
    <!--
    <section class="bg-grey pb-5" v-if="loader===false">
      <div class="container">
        <div class="row d-flex justify-content-center pos-100">
          <div class="form-row  col-md-12 mb-3">
            <div class="col-md-6 d-flex justify-content-start">
              <h3>Accessories</h3>
            </div>
            <div class="col-md-6 d-flex justify-content-end">
              <router-link to='/breast_pumps' @click="hideTopbar">
                <p class="text-pri">More</p>
              </router-link>
            </div>
          </div>
          <div class="col-md-6" v-for="accessory in accessories">
            <img :src="getImgUrl(accessory.slug, accessory.image)" alt class="img-fluid" />
            <h5 class="text-black">{{accessory.name}}</h5>
            <div class="text-dark">
              {{accessory.details_stub}}
              <router-link :to="`/breast_pumps/pump_details/${accessory.slug}`">more info</router-link>
            </div>
            <button @click="applyProduct(accessory)" class="btn btn-primary my-2">Apply Now</button>
          </div>
        </div>
      </div>
    </section>
  -->
    <product-apply v-bind:productData="selectedProduct" :detailed=false></product-apply>
  </div>
</template>

<script>
    import Product from '@/views/site/Product'
    import ProductApply from '@/views/site/ProductApply'
    export default {
        name: "HomeView",
        components: {
            Product,
            ProductApply
        },
        data() {
            return {
                collections: [],
                accessories: [],
                selectedProduct: [],
                loader: false,
            };
        },
        created() {
            this.getHomeProducts();
        },
        methods: {
            getHomeProducts() {
                this.loader = true;
                var method_type = "GET";
                var fetch_url = process.env.VUE_APP_URL + "api/getProductHomepage";
                fetch(fetch_url, {
                        method: method_type,
                        headers: {
                            "content-type": "application/json",
                            "X-Requested-With": "XMLHttpRequest",
                            Authorization: "Bearer " + $('meta[name="api_token"]').attr("content")
                        },
                    })
                    .then(res => res.json())
                    .then(data => {
                        if (data != null) {
                            this.collections = data.collections;
                            this.accessories = data.accessories;
                        }
                        this.loader = false;
                    })
            },
            showOrderModal(productEmited) {
                this.selectedProduct = productEmited;
                this.$bvModal.show('productapply-modal')
            },
            hideTopbar() {},
        }
    };
</script>