<template>
  <div id="product-apply">
    <b-modal id="productapply-modal" scrollable ref="my-modal" hide-footer hide-header size="lg">
      <div class="modal-dialog modal-dialog-centered  modal-lg" role="document" style="margin: 0px !important;">
        <div class="modal-content">
          <div class="modal-header">
            <div class="container-fluid mx-0 px-0">
              <div class="row py-2 px-2">
                <div class="col-md-4 px-5 col-sm-12">
                  <img :src="getImgUrl()" class="img-fluid" alt />
                </div>
                <div class="col-md-8 col-sm-12 ">
                  <h4 class="modal-head px-0">{{productData.name}}</h4>
                  {{productData.details_stub}}
                  <router-link :to="`/breast_pumps/pump_details/${productData.slug}`" v-if="!detailed">more info
                  </router-link>
                  <a href="#" v-if="detailed" @click="resetForm">more info</a>
                </div>
              </div>
            </div>


            <button type="button" class="close" @click="resetForm" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-center">
              <img src="@/assets/site/images/loader.gif" v-if="loader===true" class="img-fluid" style="width:15%" alt />
            </div>
            <div class="container" v-if="loader===false">
              <div class="row py-2 px-2">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" :class="{'is-invalid': errors.first_name}"
                      v-model="first_name" placeholder="First Name*" />
                    <span class="text-danger" v-if="errors.first_name">{{this.errors.first_name[0] }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="last_name" :class="{'is-invalid': errors.last_name}"
                      class="form-control" placeholder="Last Name*" />
                    <span class="text-danger" v-if="errors.last_name">{{this.errors.last_name[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="row py-2 px-2">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="email" v-model="email" :class="{'is-invalid': errors.email}" class="form-control"
                      aria-describedby="emailHelp" placeholder="Email Address*" />
                    <span class="text-danger" v-if="errors.email">{{this.errors.email[0] }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="phone" :class="{'is-invalid': errors.phone}" class="form-control"
                      placeholder="Phone Number*" />
                    <span class="text-danger" v-if="errors.phone">{{this.errors.phone[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="row py-1 px-2">
                <div class="col-md-12">
                  <label class="wrap">
                    <select class="form-control noBorder" v-model="insurance_id">
                      <option value=''>-Select Insurance-</option>
                      <option v-for="carrier in carriers" :value="carrier.id">{{carrier.name}}</option>
                      <!-- <option value="Other">Other</option> -->
                    </select>
                  </label>
                  <span class="text-danger" v-if="errors.insurance_id">{{this.errors.insurance_id[0] }}</span>`
                </div>
              </div>
              <div class="row py-2 px-2" v-if="insurance_id != '' && insurance_id != '6' ">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" :class="{'is-invalid': errors.policy_number}"
                      v-model="policy_number" placeholder="Insurance ID/Policy number" />
                    <span class="text-danger" v-if="errors.policy_number">{{this.errors.policy_number[0] }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <!-- <b-form-datepicker hide-header  placeholder="Date of Birth*" class="form-control noBorder dateHeight" v-model="date_of_birth" ></b-form-datepicker> -->
                    <input type="date" class="form-control" v-model="date_of_birth">
                    <span class="text-danger" v-if="errors.date_of_birth">{{this.errors.date_of_birth[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pl-4 mb-4" v-if="loader===false">
            <div class="row py-2 px-2">
              <div class="col-md-12 text-center" v-if="insurance_id == '6' ">
                <p class="text-primary"> <strong>Please call 808-691-9973 to discuss breast pump options </strong></p>
                <p class="text-primary"> <strong> for your other health insurance </strong></p>
              </div>
            </div>
            <div class="row py-2 px-2">
              <div class="col-md-9 text-left">
                <p class>A representative will reach out to you within 72 hours</p>
              </div>
              <div class="col-md-3">
                <button type="button" @click="applyProduct" class="btn btn-primary px-5">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
    export default {
        name: "ProductApply",
        props: ["productData", "detailed"],
        data() {
            return {
                first_name: null,
                last_name: null,
                email: null,
                phone: null,
                insurance_id: '',
                errors: [],
                loader: false,
                carriers: [],
                date_of_birth: "1990-01-01",
                policy_number: null,
            };
        },
        created() {
            this.getCarriers();
        },
        methods: {
            hideModal() {
                this.$refs['my-modal'].hide()
            },
            getImgUrl() {
                return process.env.VUE_APP_URL + "storage/uploads/" + this.productData.slug + "/" + this.productData.image;
            },
            applyProduct() {
                //Save or update User Details
                this.errors = [];
                this.loader = true;
                let apiUrl = process.env.VUE_APP_URL + "api/saveApplyProduct";
                let method_type = "post";
                fetch(apiUrl, {
                        method: method_type,
                        headers: {
                            "content-type": "application/json",
                            "X-Requested-With": "XMLHttpRequest",
                            Authorization: "Bearer " + $('meta[name="api_token"]').attr("content")
                        },
                        body: JSON.stringify({
                            first_name: this.first_name,
                            last_name: this.last_name,
                            email: this.email,
                            phone: this.phone,
                            insurance_id: this.insurance_id,
                            product_id: this.productData.id,
                            date_of_birth: this.date_of_birth,
                            policy_number: this.policy_number,
                        })
                    })
                    .then(res => res.json())
                    .then(data => {
                        if (data.status === true) {
                            this.resetForm();
                            this.$swal("Success", data.message, "success");
                            this.date_of_birth = null;
                            this.policy_number = null;
                        } else if (data.status === false) {
                            this.$swal("Error", data.message, "error");
                        } else {
                            this.errors = data.errors;
                        }
                        this.loader = false;
                    })
                    .catch(function(err) {
                        console.log("Conatct Data", "Error : " + err.message, "error");
                    });
            },
            resetForm() {
                this.first_name = null;
                this.last_name = null;
                this.email = null;
                this.phone = null;
                this.insurance_id = "";
                this.errors = [];
                this.$bvModal.hide('productapply-modal')
            },
            getCarriers() {
                var method_type = "GET";
                var fetch_url = process.env.VUE_APP_URL + "api/getBrestPumbCarriers";
                fetch(fetch_url, {
                        method: method_type,
                        headers: {
                            "content-type": "application/json",
                            "X-Requested-With": "XMLHttpRequest",
                        },
                    })
                    .then(res => res.json())
                    .then(data => {
                        if (data != null) {
                            this.carriers = data.carriers;
                        }
                    })
            },
        }
    };
</script>